import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';
import { ChevronLeft } from '@material-ui/icons';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
  ContentfulTopicPortfolioFragmentFragment,
  PortfolioTemplateQuery,
} from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import edgepointTheme from '../themes/edgepoint';
import cymbriaTheme from '../themes/cymbria';
import Layout from '../components/Layout';
import { PageContext } from '../types/pageContext';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/SEO';
import { useGlobalState } from '../hooks/useGlobalState';
import Typography from '../components/Typography';
import ComponentSelector from '../components/ComponentSelector';
import { getTranslation } from '../services/translation.service';
import Link from '../components/Link';
import Modal from '../components/Modal';
import CookiePreferences from '../components/CookiePreferences';

export const query = graphql`
  query PortfolioTemplate(
    $id: String!
    $node_locale: String!
    $globalWebsiteContentfulId: String!
  ) {
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      __typename
      slug
      title
      shortDescription {
        shortDescription
      }
      seo {
        ...ContentfulComposeSeoFragment
      }
      content {
        ... on ContentfulTopicPortfolio {
          ...ContentfulTopicPortfolioFragment
        }
      }
      website {
        ...WebsiteFragment
      }
      pageHeader {
        ...ContentfulComponentSiteHeaderFragment
      }
      pageFooter {
        ...ContentfulComponentSiteFooterFragment
      }
    }
    allContentfulTopicPortfolioSeries(
      filter: {
        node_locale: { eq: $node_locale }
        topic__portfolio: {
          elemMatch: {
            compose__page: {
              elemMatch: {
                website: { contentful_id: { eq: $globalWebsiteContentfulId } }
              }
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          title
          node_locale
          instrumentKey
          inceptionDate
          isGated
          filterGroup
          nonHst
          series {
            id
            title
          }
          topic__portfolio {
            id
            title
            compose__page {
              slug
            }
          }
        }
      }
    }
    portfoliosOverviewPage: contentfulComposePage(
      isPortfoliosOverviewPage: { eq: true }
      node_locale: { eq: $node_locale }
      website: { contentful_id: { eq: $globalWebsiteContentfulId } }
    ) {
      slug
      title
    }
  }
`;

const Page: React.FC<PageProps<PortfolioTemplateQuery, PageContext>> = (
  props,
) => {
  const { data, location } = props;
  const { contentfulComposePage, portfoliosOverviewPage } = data;
  const { site } = useGlobalState();
  const { locale } = useLocalization();

  const content =
    contentfulComposePage?.content as ContentfulTopicPortfolioFragmentFragment;
  const portfolioDetails = content?.portfolioDetails;
  const forEligibleInvestorsOnly = content?.forEligibleInvestorsOnly;

  const mobileMenuPaddingClasses = 'pt-m4 lg:pt-0';

  const cookiePreferences = contentfulComposePage?.website?.cookiePreferences;

  const pageHeader =
    (contentfulComposePage?.pageHeader as unknown as ContentfulComponentSiteHeader) ??
    (contentfulComposePage?.website
      ?.header as unknown as ContentfulComponentSiteHeader);

  const pageFooter =
    (contentfulComposePage?.pageFooter as unknown as ContentfulComponentSiteFooter) ??
    (contentfulComposePage?.website
      ?.footer as unknown as ContentfulComponentSiteFooter);

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={contentfulComposePage?.seo} location={location} />
      <Header
        data={pageHeader}
        location={location}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        mobileLogo={contentfulComposePage?.website?.siteAssets?.mobileLogo}
      />
      <main id="main" className={`${mobileMenuPaddingClasses}`}>
        <div className="container mt-m3">
          {portfoliosOverviewPage && (
            <Link
              language={locale}
              link={{ page: portfoliosOverviewPage as ContentfulComposePage }}
            >
              <Typography
                as="div"
                variant="footerBody3"
                className="inline-flex items-center"
              >
                <ChevronLeft className="text-secondary" />
                {getTranslation('BackTo', locale)}{' '}
                {portfoliosOverviewPage.title}
              </Typography>
            </Link>
          )}
          {contentfulComposePage?.title && (
            <div className="mt-s1">
              <Typography as="h2" variant="h1">
                {contentfulComposePage?.title}
              </Typography>
            </div>
          )}
          {contentfulComposePage?.shortDescription?.shortDescription && (
            <div className="mt-s2">
              <Typography as="p" variant="body">
                {contentfulComposePage?.shortDescription?.shortDescription}
              </Typography>
            </div>
          )}
        </div>
        {portfolioDetails && (
          <ComponentSelector contentfulComponent={portfolioDetails} />
        )}
      </main>
      <Footer
        data={pageFooter}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        socialLinks={contentfulComposePage?.website?.siteAssets?.socialLinks}
      />
      {forEligibleInvestorsOnly && (
        <Modal
          id={content?.id}
          headline={content?.variableInvestorsHeadline}
          body={content?.variableInvestorsBody}
          disclaimer={content?.variableInvestorsDisclaimer}
          rememberLabel={content?.variableInvestorsRememberLabel}
          declineButton={content?.variableInvestorsDeclineButton}
          acceptButton={content?.variableInvestorsAcceptButton}
        />
      )}

      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default Page;
