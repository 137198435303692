import React from 'react';
import { navigate } from 'gatsby-link';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  DialogActions,
  useMediaQuery,
} from '@material-ui/core';
import {
  ContentfulComponentCtaButton,
  ContentfulTopicPortfolioVariableInvestorsBody,
  ContentfulTopicPortfolioVariableInvestorsDisclaimer,
} from '../../graphql-types';
import Typography from './Typography';
import Button from './Button';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { ThemeContext } from 'styled-components';

interface ModalProps {
  id?: string;
  headline?: string;
  body?: ContentfulTopicPortfolioVariableInvestorsBody;
  disclaimer?: ContentfulTopicPortfolioVariableInvestorsDisclaimer;
  rememberLabel?: string;
  declineButton?: ContentfulComponentCtaButton;
  acceptButton?: ContentfulComponentCtaButton;
}
const Modal: React.FC<ModalProps> = (props) => {
  const {
    id,
    headline,
    body,
    disclaimer,
    rememberLabel,
    declineButton,
    acceptButton,
  } = props;
  const cookieKeyName = `Accepted-${id}`;
  const theme = React.useContext(ThemeContext);
  const { locale } = useLocalization();

  // modal state and actions
  const isDesktopSize = useMediaQuery(`(min-width:${theme.breakpoint.sm})`);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleClose = (event?: object, reason?: string) => {
    if (reason === 'backdropClick') {
      // do not close on backdrop click
      return;
    }
    setOpen(false);
  };

  // state for cookie remember variable
  const [isRememberedChecked, setState] = React.useState(false);
  const handleRememberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  /**
   * Handler for when user chooses to Accept modal terms. If the checkbox for
   * remembering their decision is checked, it will store a cookie to prevent
   * this modal from auto-matically opening in the future.
   */
  const handleAccept = () => {
    if (isRememberedChecked) {
      document.cookie = `${cookieKeyName}=true;`;
    }
    handleClose();
  };

  /**
   * Handler for when user chooses to Decline modal terms. This will expire any
   * cookie values and redirect the user to the home page.
   */
  const handleDecline = () => {
    // expire cookie if user declines and redirect
    document.cookie = `${cookieKeyName}=false;`;
    document.cookie = `Max-Age=0;`;
    navigate(`/${locale !== 'en' ? locale : ''}`);
    handleClose();
  };

  React.useEffect(() => {
    // get cookie and auto-open modal if they have not accepted the agreement
    const acceptedCookieValue = document.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith(`${cookieKeyName}=`))
      ?.split('=')[1];

    if (!acceptedCookieValue || acceptedCookieValue === 'false') {
      setOpen(true);
    }
  }, []);

  return (
    <Dialog
      fullScreen={!isDesktopSize}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      {headline && (
        <DialogTitle id="modal-title">
          <Typography variant="h2">{headline}</Typography>
        </DialogTitle>
      )}
      <DialogContent id="modal-description">
        {body && renderContentfulRichText(body)}
        {disclaimer && renderContentfulRichText(disclaimer)}
      </DialogContent>
      <DialogActions className="flex-col sm:flex-row">
        {declineButton && (
          <Button
            variant={declineButton.variant}
            onClick={handleDecline}
            className="ml-auto sm:ml-0 sm:mr-auto px-s2 py-s1 sm:py-0"
          >
            {declineButton.text}
          </Button>
        )}
        <div className="flex ml-auto sm:ml-0">
          {rememberLabel && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRememberedChecked}
                  onChange={handleRememberChange}
                  name="remember"
                  color="secondary"
                />
              }
              label={rememberLabel}
            />
          )}
          {acceptButton && (
            <Button variant={acceptButton.variant} onClick={handleAccept}>
              {acceptButton.text}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
